var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"py-0 px-0"},[(!_vm.homeSingleton.cover !== null)?[(!_vm.homeSingleton.cover.image)?[_c('div',{staticClass:"position-relative"},[_c('video',{staticClass:"d-block asset-intro",staticStyle:{"width":"100%","object-fit":"cover"},attrs:{"src":_vm.homeSingleton.cover !== null
                    ? 'https://www.lakewaycms.actstudio.xyz/storage/uploads' +
                      _vm.homeSingleton.cover.path
                    : '',"autoplay":"","muted":"","loop":"","playsinline":""},domProps:{"muted":true}}),_c('div',{staticClass:"position-absolute background-home-cover"}),_c('div',{staticClass:"position-absolute icon-home-cover"},[_c('span',{staticClass:"white--text bold icon-home-cover-span d-block"},[_vm._v(_vm._s(_vm.homeSingleton.block_1_texts.text1))]),_c('span',{staticClass:"white--text pinyon bold icon-home-cover-span-2"},[_vm._v(_vm._s(_vm.homeSingleton.block_1_texts.text2))])])])]:[_c('v-img',{staticClass:"asset-intro",attrs:{"src":_vm.homeSingleton.cover !== null
                  ? 'https://www.lakewaycms.actstudio.xyz/storage/uploads' +
                    _vm.homeSingleton.cover.path
                  : '',"width":"100%","eager":""}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"white--text pinyon bold icon-home-cover-span"},[_vm._v(_vm._s(_vm.homeSingleton.block_1_texts.text1))]),_c('span',{staticClass:"white--text pinyon bold icon-home-cover-span"},[_vm._v(_vm._s(_vm.homeSingleton.block_1_texts.text2))])])],1)],1)]]:_vm._e()],2)],1)],1),_c('div',{staticClass:"second-block-custom",staticStyle:{"background-color":"#f7f4eb"}},[_c('v-container',{staticClass:"py-12",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"px-12 padding-right-first-column",attrs:{"cols":"12","md":"auto"}},[(_vm.homeSingleton.block_2_image !== null)?[_c('v-img',{staticClass:"second-block-img",attrs:{"src":'https://www.lakewaycms.actstudio.xyz/storage/uploads' +
                _vm.homeSingleton.block_2_image.path,"eager":""}})]:_vm._e()],2),_c('v-col',{staticClass:"text-center text-md-left",attrs:{"cols":"12","md":"auto"}},[_c('div',{staticClass:"div-max-width-13"},[_c('span',{staticClass:"d-block markazi semibold titles-app"},[_vm._v(_vm._s(_vm.homeSingleton.block_2_texts.text))]),(_vm.homeSingleton.block_2_texts.switch)?_c('v-btn',{staticClass:"mt-12 btn-general",attrs:{"to":'/' + _vm.homeSingleton.block_2_texts.button_link,"ripple":false,"color":"#E3D7B8","elevation":"0","dark":"","rounded":""}},[_vm._v(_vm._s(_vm.homeSingleton.block_2_texts.button_text))]):_vm._e()],1)])],1)],1)],1),_c('v-container',{staticClass:"margin-custom-third-block",attrs:{"fluid":""}},[_c('v-row',{staticClass:"py-12"},[_c('v-col',{staticClass:"text-center"},[_c('span',{staticClass:"d-block semibold title-third-block titles-app-new",domProps:{"innerHTML":_vm._s(_vm.homeSingleton.block_3_title)}})])],1),(!_vm.$vuetify.breakpoint.xs)?_c('v-row',{staticClass:"mb-0"},[_c('v-col',{staticClass:"px-0 pr-2 py-0",attrs:{"cols":"4"}},[(_vm.homeSingleton.block_3_img1 !== null)?_c('v-img',{staticClass:"third-block-img",attrs:{"src":'https://www.lakewaycms.actstudio.xyz' +
            _vm.homeSingleton.block_3_img1.path,"aspect-ratio":"1.5","eager":""}}):_vm._e()],1),_c('v-col',{staticClass:"px-1 py-0",attrs:{"cols":"4"}},[(_vm.homeSingleton.block_3_img2 !== null)?_c('v-img',{staticClass:"third-block-img",attrs:{"src":'https://www.lakewaycms.actstudio.xyz' +
            _vm.homeSingleton.block_3_img2.path,"aspect-ratio":"1.5","eager":""}}):_vm._e()],1),_c('v-col',{staticClass:"px-0 pl-2 py-0",attrs:{"cols":"4"}},[(_vm.homeSingleton.block_3_img3 !== null)?_c('v-img',{staticClass:"third-block-img",attrs:{"src":'https://www.lakewaycms.actstudio.xyz' +
            _vm.homeSingleton.block_3_img3.path,"aspect-ratio":"1.5","eager":""}}):_vm._e()],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.xs)?_c('v-row',[_c('v-carousel',{attrs:{"height":_vm.$vuetify.breakpoint.width,"show-arrows":false,"cycle":"","hide-delimiters":""}},[_c('v-carousel-item',[(_vm.homeSingleton.block_3_img1 !== null)?_c('v-img',{attrs:{"src":'https://www.lakewaycms.actstudio.xyz' +
              _vm.homeSingleton.block_3_img1.path,"aspect-ratio":"1","eager":""}}):_vm._e()],1),_c('v-carousel-item',[(_vm.homeSingleton.block_3_img2 !== null)?_c('v-img',{attrs:{"src":'https://www.lakewaycms.actstudio.xyz' +
              _vm.homeSingleton.block_3_img2.path,"aspect-ratio":"1","eager":""}}):_vm._e()],1),_c('v-carousel-item',[(_vm.homeSingleton.block_3_img3 !== null)?_c('v-img',{attrs:{"src":'https://www.lakewaycms.actstudio.xyz' +
              _vm.homeSingleton.block_3_img3.path,"aspect-ratio":"1","eager":""}}):_vm._e()],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"text-center py-0"},[_c('v-btn',{staticClass:"btn-general",staticStyle:{"transform":"translateY(-50%)"},attrs:{"to":'/' + _vm.homeSingleton.block_3_btn.button_link,"ripple":false,"color":"#E3D7B8","elevation":"0","dark":"","rounded":""}},[_vm._v(_vm._s(_vm.homeSingleton.block_3_btn.button_text))])],1)],1)],1),_c('v-container',{attrs:{"fluid":_vm.$vuetify.breakpoint.lgAndDown || _vm.$vuetify.breakpoint.width > 2299}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"auto","xl":_vm.$vuetify.breakpoint.width > 2299 ? 'auto' : 6}},[(_vm.homeSingleton.block_4_img !== null)?_c('v-img',{staticClass:"fourth-block-img",attrs:{"src":'https://www.lakewaycms.actstudio.xyz' +
            _vm.homeSingleton.block_4_img.path,"eager":""}}):_vm._e()],1),_c('v-col',{staticClass:"text-center second-column-4161",attrs:{"cols":"12","sm":"auto","xl":_vm.$vuetify.breakpoint.width > 2299 ? 'auto' : 6}},[_c('span',{staticClass:"d-block subtitle-81658 pinyon semibold"},[_vm._v(_vm._s(_vm.homeSingleton.block_4_card.title))]),_c('span',{staticClass:"d-block bold mx-auto description-1615"},[_vm._v(_vm._s(_vm.homeSingleton.block_4_card.description))]),_c('v-btn',{staticClass:"mt-2 mt-xl-6 btn-general",attrs:{"elevation":"0","color":"#E3D7B8","dark":"","rounded":"","to":'/' + _vm.homeSingleton.block_4_card.button_link,"ripple":false}},[_vm._v(_vm._s(_vm.homeSingleton.block_4_card.button_text))])],1)],1)],1),_c('div',{staticClass:"margin-custom-fifth-block",staticStyle:{"background-color":"#f7f4eb"}},[_c('v-container',{staticClass:"div-tree-imgs",attrs:{"fluid":_vm.$vuetify.breakpoint.mdAndDown || _vm.$vuetify.breakpoint.width > 2299 ? true : false}},[_c('v-row',{staticStyle:{"width":"100%"},attrs:{"align":"end","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"auto","lg":"4"}},[(_vm.homeSingleton.block_5_card1.img !== null)?_c('v-img',{staticClass:"image-move-up-hover mx-auto",attrs:{"src":'https://www.lakewaycms.actstudio.xyz' +
              _vm.homeSingleton.block_5_card1.img.path,"contain":"","eager":""}}):_vm._e(),_c('span',{staticClass:"d-block mt-6 text-title-circle bold"},[_vm._v(_vm._s(_vm.homeSingleton.block_5_card1.title))]),_c('span',{staticClass:"d-block pinyon semibold text-title-circle-2"},[_vm._v(_vm._s(_vm.homeSingleton.block_5_card1.text))])],1),_c('v-col',{staticClass:"text-center column-center-165",attrs:{"cols":"12","md":"auto","lg":"auto"}},[(_vm.homeSingleton.block_5_card2.img !== null)?_c('v-img',{staticClass:"image-move-up-hover mx-auto",attrs:{"src":'https://www.lakewaycms.actstudio.xyz' +
              _vm.homeSingleton.block_5_card2.img.path,"contain":""}}):_vm._e(),_c('span',{staticClass:"d-block mt-6 text-title-circle bold"},[_vm._v(_vm._s(_vm.homeSingleton.block_5_card2.title))]),_c('span',{staticClass:"d-block pinyon semibold text-title-circle-2"},[_vm._v(_vm._s(_vm.homeSingleton.block_5_card2.text))])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"auto","lg":"4"}},[(_vm.homeSingleton.block_5_card3.img !== null)?_c('v-img',{staticClass:"image-move-up-hover mx-auto",attrs:{"src":'https://www.lakewaycms.actstudio.xyz' +
              _vm.homeSingleton.block_5_card3.img.path,"contain":""}}):_vm._e(),_c('span',{staticClass:"d-block mt-6 text-title-circle bold"},[_vm._v(_vm._s(_vm.homeSingleton.block_5_card3.title))]),_c('span',{staticClass:"d-block pinyon semibold text-title-circle-2"},[_vm._v(_vm._s(_vm.homeSingleton.block_5_card3.text))])],1)],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"px-0 py-0"},[_c('v-img',{staticClass:"align-center text-center py-12 py-sm-0 banner-block-6",attrs:{"src":_vm.homeSingleton.block_6_banner.cover !== null
              ? 'https://www.lakewaycms.actstudio.xyz' +
                _vm.homeSingleton.block_6_banner.cover.path
              : '',"eager":""}},[_c('span',{staticClass:"\n              d-block\n              white--text\n              markazi\n              semibold\n              mx-auto\n              mb-6\n              titles-app\n              contact-us-block-home\n            "},[_vm._v(_vm._s(_vm.homeSingleton.block_6_banner.text))]),_c('v-btn',{staticClass:"btn-general btn-contact-us",attrs:{"color":"#E3D7B8","dark":"","rounded":"","elevation":"0","to":'/' + _vm.homeSingleton.block_6_banner.button_link,"ripple":false}},[_vm._v(_vm._s(_vm.homeSingleton.block_6_banner.button_text))])],1)],1)],1)],1),_c('Certifications')],1)}
var staticRenderFns = []

export { render, staticRenderFns }