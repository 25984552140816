<template>
  <div class="home">
    <v-container fluid class="py-0">
      <v-row class="my-0">
        <v-col class="py-0 px-0">
          <template v-if="!homeSingleton.cover !== null">
            <template v-if="!homeSingleton.cover.image">
              <div class="position-relative">
                <video
                  :src="
                    homeSingleton.cover !== null
                      ? 'https://www.lakewaycms.actstudio.xyz/storage/uploads' +
                        homeSingleton.cover.path
                      : ''
                  "
                  class="d-block asset-intro"
                  autoplay
                  muted
                  style="width: 100%; object-fit: cover"
                  loop
                  playsinline
                ></video>
                <div class="position-absolute background-home-cover"></div>
                <div class="position-absolute icon-home-cover">
                  <span class="white--text bold icon-home-cover-span d-block">{{
                    homeSingleton.block_1_texts.text1
                  }}</span>
                  <span
                    class="white--text pinyon bold icon-home-cover-span-2"
                    >{{ homeSingleton.block_1_texts.text2 }}</span
                  >
                </div>
              </div>
            </template>
            <template v-else>
              <v-img
                :src="
                  homeSingleton.cover !== null
                    ? 'https://www.lakewaycms.actstudio.xyz/storage/uploads' +
                      homeSingleton.cover.path
                    : ''
                "
                width="100%"
                eager
                class="asset-intro"
              >
                <v-row class="fill-height" align="center" justify="center">
                  <v-col cols="auto">
                    <span
                      class="white--text pinyon bold icon-home-cover-span"
                      >{{ homeSingleton.block_1_texts.text1 }}</span
                    >
                    <span
                      class="white--text pinyon bold icon-home-cover-span"
                      >{{ homeSingleton.block_1_texts.text2 }}</span
                    >
                  </v-col>
                </v-row>
              </v-img>
            </template>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <div class="second-block-custom" style="background-color: #f7f4eb">
      <v-container class="py-12" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" md="auto" class="px-12 padding-right-first-column">
            <template v-if="homeSingleton.block_2_image !== null">
              <v-img
                :src="
                  'https://www.lakewaycms.actstudio.xyz/storage/uploads' +
                  homeSingleton.block_2_image.path
                "
                class="second-block-img"
                eager
              ></v-img>
            </template>
          </v-col>
          <v-col cols="12" md="auto" class="text-center text-md-left">
            <div class="div-max-width-13">
              <span class="d-block markazi semibold titles-app">{{
                homeSingleton.block_2_texts.text
              }}</span>
              <v-btn
                v-if="homeSingleton.block_2_texts.switch"
                v-bind:to="'/' + homeSingleton.block_2_texts.button_link"
                v-bind:ripple="false"
                color="#E3D7B8"
                class="mt-12 btn-general"
                elevation="0"
                dark
                rounded
                >{{ homeSingleton.block_2_texts.button_text }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-container fluid class="margin-custom-third-block">
      <v-row class="py-12">
        <v-col class="text-center">
          <span
            class="d-block semibold title-third-block titles-app-new"
            v-html="homeSingleton.block_3_title"
          >
          </span>
        </v-col>
      </v-row>
      <v-row v-if="!$vuetify.breakpoint.xs" class="mb-0">
        <v-col cols="4" class="px-0 pr-2 py-0">
          <v-img
            v-if="homeSingleton.block_3_img1 !== null"
            v-bind:src="
              'https://www.lakewaycms.actstudio.xyz' +
              homeSingleton.block_3_img1.path
            "
            aspect-ratio="1.5"
            class="third-block-img"
            eager
          ></v-img>
        </v-col>
        <v-col cols="4" class="px-1 py-0">
          <v-img
            v-if="homeSingleton.block_3_img2 !== null"
            v-bind:src="
              'https://www.lakewaycms.actstudio.xyz' +
              homeSingleton.block_3_img2.path
            "
            aspect-ratio="1.5"
            class="third-block-img"
            eager
          ></v-img>
        </v-col>
        <v-col cols="4" class="px-0 pl-2 py-0">
          <v-img
            v-if="homeSingleton.block_3_img3 !== null"
            v-bind:src="
              'https://www.lakewaycms.actstudio.xyz' +
              homeSingleton.block_3_img3.path
            "
            aspect-ratio="1.5"
            class="third-block-img"
            eager
          ></v-img>
        </v-col>
      </v-row>
      <v-row v-if="$vuetify.breakpoint.xs">
        <v-carousel
          :height="$vuetify.breakpoint.width"
          :show-arrows="false"
          cycle
          hide-delimiters
        >
          <v-carousel-item>
            <v-img
              v-if="homeSingleton.block_3_img1 !== null"
              v-bind:src="
                'https://www.lakewaycms.actstudio.xyz' +
                homeSingleton.block_3_img1.path
              "
              aspect-ratio="1"
              eager
            ></v-img>
          </v-carousel-item>
          <v-carousel-item>
            <v-img
              v-if="homeSingleton.block_3_img2 !== null"
              v-bind:src="
                'https://www.lakewaycms.actstudio.xyz' +
                homeSingleton.block_3_img2.path
              "
              aspect-ratio="1"
              eager
            ></v-img>
          </v-carousel-item>
          <v-carousel-item>
            <v-img
              v-if="homeSingleton.block_3_img3 !== null"
              v-bind:src="
                'https://www.lakewaycms.actstudio.xyz' +
                homeSingleton.block_3_img3.path
              "
              aspect-ratio="1"
              eager
            ></v-img>
          </v-carousel-item>
        </v-carousel>
      </v-row>
      <v-row class="mt-0">
        <v-col class="text-center py-0">
          <v-btn
            :to="'/' + homeSingleton.block_3_btn.button_link"
            :ripple="false"
            color="#E3D7B8"
            elevation="0"
            style="transform: translateY(-50%)"
            class="btn-general"
            dark
            rounded
            >{{ homeSingleton.block_3_btn.button_text }}</v-btn
          >
        </v-col>
      </v-row>
    </v-container>

    <v-container :fluid="$vuetify.breakpoint.lgAndDown || $vuetify.breakpoint.width > 2299">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="auto" :xl="$vuetify.breakpoint.width > 2299 ? 'auto' : 6">
          <v-img
            v-if="homeSingleton.block_4_img !== null"
            v-bind:src="
              'https://www.lakewaycms.actstudio.xyz' +
              homeSingleton.block_4_img.path
            "
            class="fourth-block-img"
            eager
          ></v-img>
        </v-col>
        <v-col
          cols="12"
          sm="auto"
          :xl="$vuetify.breakpoint.width > 2299 ? 'auto' : 6"
          class="text-center second-column-4161"
        >
          <span class="d-block subtitle-81658 pinyon semibold">{{
            homeSingleton.block_4_card.title
          }}</span>
          <span class="d-block bold mx-auto description-1615">{{
            homeSingleton.block_4_card.description
          }}</span>
          <v-btn
            elevation="0"
            color="#E3D7B8"
            dark
            rounded
            :to="'/' + homeSingleton.block_4_card.button_link"
            :ripple="false"
            class="mt-2 mt-xl-6 btn-general"
            >{{ homeSingleton.block_4_card.button_text }}</v-btn
          >
        </v-col>
      </v-row>
    </v-container>

    <div style="background-color: #f7f4eb" class="margin-custom-fifth-block">
      <v-container
        class="div-tree-imgs"
        :fluid="$vuetify.breakpoint.mdAndDown || $vuetify.breakpoint.width > 2299 ? true : false"
        >
        <v-row align="end" justify="center" style="width: 100%">
          <v-col cols="12" md="auto" lg="4" class="text-center">
            <v-img
              v-if="homeSingleton.block_5_card1.img !== null"
              v-bind:src="
                'https://www.lakewaycms.actstudio.xyz' +
                homeSingleton.block_5_card1.img.path
              "
              class="image-move-up-hover mx-auto"
              contain
              eager
            ></v-img>
            <span class="d-block mt-6 text-title-circle bold">{{
              homeSingleton.block_5_card1.title
            }}</span>
            <span class="d-block pinyon semibold text-title-circle-2">{{
              homeSingleton.block_5_card1.text
            }}</span>
          </v-col>
          <v-col
            cols="12"
            md="auto"
            lg="auto"
            class="text-center column-center-165"
          >
            <v-img
              v-if="homeSingleton.block_5_card2.img !== null"
              v-bind:src="
                'https://www.lakewaycms.actstudio.xyz' +
                homeSingleton.block_5_card2.img.path
              "
              class="image-move-up-hover mx-auto"
              contain
            ></v-img>
            <span class="d-block mt-6 text-title-circle bold">{{
              homeSingleton.block_5_card2.title
            }}</span>
            <span class="d-block pinyon semibold text-title-circle-2">{{
              homeSingleton.block_5_card2.text
            }}</span>
          </v-col>
          <v-col cols="12" md="auto" lg="4" class="text-center">
            <v-img
              v-if="homeSingleton.block_5_card3.img !== null"
              v-bind:src="
                'https://www.lakewaycms.actstudio.xyz' +
                homeSingleton.block_5_card3.img.path
              "
              class="image-move-up-hover mx-auto"
              contain
            ></v-img>
            <span class="d-block mt-6 text-title-circle bold">{{
              homeSingleton.block_5_card3.title
            }}</span>
            <span class="d-block pinyon semibold text-title-circle-2">{{
              homeSingleton.block_5_card3.text
            }}</span>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-container fluid>
      <v-row>
        <v-col class="px-0 py-0">
          <v-img
            v-bind:src="
              homeSingleton.block_6_banner.cover !== null
                ? 'https://www.lakewaycms.actstudio.xyz' +
                  homeSingleton.block_6_banner.cover.path
                : ''
            "
            class="align-center text-center py-12 py-sm-0 banner-block-6"
            eager
          >
            <span
              class="
                d-block
                white--text
                markazi
                semibold
                mx-auto
                mb-6
                titles-app
                contact-us-block-home
              "
              >{{ homeSingleton.block_6_banner.text }}</span
            >
            <v-btn
              class="btn-general btn-contact-us"
              color="#E3D7B8"
              dark
              rounded
              elevation="0"
              :to="'/' + homeSingleton.block_6_banner.button_link"
              :ripple="false"
              >{{ homeSingleton.block_6_banner.button_text }}</v-btn
            >
          </v-img>
        </v-col>
      </v-row>
    </v-container>

    <!-- <v-container class="div-certifications" :fluid="$vuetify.breakpoint.lgAndDown ? true : false">
      <v-row class="my-12" style="width: 100%">
        <v-col class="text-center">
          <h1 class="markazi titles-app">Our certifications</h1>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="margin-custom-16581">
        <v-col cols="12" lg="12" xl="11">
          <v-row justify="center" align="center">
            <v-col
              cols="auto"
              sm="3"
              md="auto"
              class="px-8 px-sm-6 mx-sm-4 mx-xl-10"
              v-for="(certificate, c) in certifications"
              v-bind:key="c"
            >
              <template v-if="certificate.cover !== null">
                <v-img
                  :src="
                    'https://www.lakewaycms.actstudio.xyz' +
                    certificate.cover.path
                  "
                  contain
                  eager
                  class="logo-certifcate"
                ></v-img>
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container> -->

    <Certifications></Certifications>
  </div>
</template>

<script>
// @ is an alias to /src
// import carousel from 'vue-owl-carousel'
import Certifications from "@/components/global/Certifications.vue";

export default {
  name: "Home",
  components: {
    Certifications,
  },
  data() {
    return {
      products: [],
      loaded: false,
      homeSingleton: {
        cover: {
          path: "",
        },
        block_2_image: {
          path: "",
        },
        block_2_texts: {
          text: "",
          button_link: "",
          button_text: "",
        },
        block_3_img1: {
          path: "",
        },
        block_3_img2: {
          path: "",
        },
        block_3_img3: {
          path: "",
        },
        block_4_img: {
          path: "",
        },
        block_4_card: {
          button_link: "",
          button_text: "",
          description: "",
          title: "",
        },
        block_3_btn: {
          button_link: "",
          button_text: "",
        },
        block_5_card1: {
          img: {
            path: "",
          },
          title: "",
          text: "",
        },
        block_5_card2: {
          img: {
            path: "",
          },
          title: "",
          text: "",
        },
        block_5_card3: {
          img: {
            path: "",
          },
          title: "",
          text: "",
        },
        block_6_banner: {
          cover: {
            path: "",
          },
          button_link: "",
          button_text: "",
          text: "",
        },
        block_1_texts: {
          text1: "",
          text2: "",
        },
      },
    };
  },
  async beforeCreate() {
    let key = "";

    const opts =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijlkmnopqrstuvwxyz1234567890";

    for (let index = 0; index < 10; index++) {
      key = key + opts.charAt(Math.random() * 61);
    }
    this.homeSingleton = await fetch(
      "https://www.lakewaycms.actstudio.xyz/api/singletons/get/home?q=" + key,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 1,
        }),
      }
    )
      .then((data) => data.json())
      .then((data) => (this.homeSingleton = data.collectionHome));
  },
  beforeMount: function () {
    fetch("https://www.lakewaycms.actstudio.xyz/api/collections/get/Products", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        filter: {},
        populate: 1, // resolve linked collection items
      }),
    })
      .then((res) => res.json())
      .then((res) => (this.products = res.entries))
      .then((this.loaded = true));
  },
};
</script>

<style lang="scss" scoped>
$xxl: 2300px;
$xl: 1903px;
$lg: 1263px;
$md: 959px;
$sm: 599px;

.div-max-width-13 {
  max-width: 873px;

  @media screen and(max-width: $xxl) {
    max-width: 700px;
  }

  @media screen and(max-width: $xl) {
    max-width: 500px;
  }

  @media screen and(max-width: $md) {
    margin: auto;
    margin-top: 30px;
  }

  @media screen and(max-width: $sm) {
    max-width: 300px;
  }
}

.padding-right-first-column {
  padding-right: 136px !important;

  @media screen and(max-width: $xl) {
    padding-right: 101px !important;
  }

  @media screen and(max-width: $lg) {
    padding-right: 85px !important;
  }

  @media screen and(max-width: $md) {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.margin-custom-third-block {
  margin: 140px 0px 170px 0px;

  @media screen and(max-width: $lg) {
    margin: 120px 0px 170px 0px;
  }

  @media screen and(max-width: $sm) {
    margin: 36px 0px 100px 0px;
  }
}

.description-1615 {
  margin-top: 10px;
  margin-bottom: 29px;
  line-height: 0.9;
  font-size: 62px;
  max-width: 621px;
  @media screen and(max-width: $xxl) {
    max-width: 440px;
    font-size: 42px;
  }

  @media screen and(max-width: $xl) {
    max-width: 300px;
    font-size: 26px;
  }
  @media screen and(max-width: $md) {
    font-size: 42px;
    max-width: 440px;
  }
  @media screen and(max-width: $sm) {
    font-size: 33px;
  }
}

.margin-custom-fifth-block {
  margin: 180px 0px 0px 0px;

  @media screen and(max-width: $sm) {
    margin-top: 134px;
  }
}

.image-move-up-hover {
  transition: 0.5s ease transform;
  transform: translateY(0px);
  height: 450px;
  width: 450px;

  @media screen and(max-width: $xxl) {
    height: 350px;
    width: 350px;
  }

  @media screen and(max-width: $xl) {
    width: 280px;
    height: 280px;
  }

  @media screen and(max-width: $lg) {
    width: 240px;
    height: 240px;
  }

  @media screen and(max-width: $md) {
    width: 260px;
    height: 260px;
  }

  @media screen and(max-width: $sm) {
    width: 240px;
    height: 240px;
  }

  &:hover {
    transform: translateY(-20px);
  }
}

.div-tree-imgs {
  padding: 110px 0px;

  @media screen and(max-width: $xl) {
    padding: 90px 0px;
  }

  @media screen and(max-width: $md) {
    padding: 180px 0px;
  }

  @media screen and(max-width: $sm) {
    padding: 106px 0px;
  }
}

.banner-block-6 {
  height: 900px !important;

  @media screen and(max-width: $xxl) {
    height: 700px !important;
  }

  @media screen and(max-width: $xl) {
    height: 480px !important;
  }

  @media screen and(max-width: $md) {
    height: 580px !important;
  }

  @media screen and(max-width: $sm) {
    height: 610px !important;
  }
}

.btn-contact-us {
  @media screen and(min-width: $xxl) {
    margin-top: 79px;
  }
  margin-top: 38px;
}

.margin-custom-16581 {
  margin: 78px 0px 48px 0px !important;

  @media screen and(max-width: $sm) {
    margin: 50px 0px 48px 0px !important;
  }
}

.second-block-custom {
  padding: 100px 0 !important;

  @media screen and(max-width: $xl) {
    padding: 38px 0 !important;
  }

  @media screen and(max-width: $md) {
    padding: 68px 0 58px 0 !important;
  }
}

.second-block-img {
  max-width: 900px;

  @media screen and(max-width: $xxl) {
    max-width: 621px;
  }

  @media screen and(max-width: $xl) {
    max-width: 469px;
    width: 469px;
    height: 312px;
  }

  @media screen and(max-width: $md) {
    margin: auto;
    max-width: 522px;
  }

  @media screen and(max-width: $sm) {
    max-width: 300px;
  }
}

.third-block-img {
  @media screen and(min-width: $xxl) {
    height: 650px;
  }
  @media screen and(max-width: $lg) {
    height: 330px;
  }
}

.fourth-block-img {
  @media screen and(min-width: $xxl) {
    width: 1200px;
    height: 850px;
  }
  @media screen and(max-width: $xl) {
    width: 500px;
    height: 300px;
  }

  @media screen and(max-width: $lg) {
    width: 500px;
    height: 350px;
  }

  @media screen and(max-width: $md) {
    width: 500px;
    height: 350px;
  }

  @media screen and(max-width: $sm) {
    width: 300px;
    height: 200px;
    margin: auto;
  }
}

.second-column-4161 {
  @media screen and(min-width: $xxl) {
    margin-left: 251px;
  }
  @media screen and(max-width: $xl) {
    margin-left: 124px;
  }

  @media screen and(max-width: $lg) {
    margin-left: 76px;
  }

  @media screen and(max-width: $md) {
    margin-top: 40px;
    margin-left: 0px;
  }

  @media screen and(max-width: $sm) {
    margin-top: 28px;
    margin-left: 0px;
    max-width: 300px;
  }
}

.column-center-165 {
  @media screen and(min-width: $xxl) {
    margin: 0 99px !important;
  }
  @media screen and(max-width: $lg) {
    margin: 0 99px !important;
  }

  @media screen and(max-width: $md) {
    margin: 38px 99px !important;
  }
}

.title-third-block {
  @media screen and(max-width: $md) {
    max-width: 636px;
    margin: auto;
  }

  @media screen and(max-width: $sm) {
    max-width: 262px;
  }
}

.asset-intro {
  height: calc(100vh - 100px);

  @media screen and(max-width: $xl) {
    height: calc(100vh - 80px);
  }
}
.text-title-circle {
    font-size: 38px;
  @media screen and(max-width: $xxl) {
    font-size: 28px;
  }
  @media screen and(max-width: $xl) {
    font-size: 18px;
  }
  @media screen and(max-width: $md) {
    font-size: 28px;
  }
  @media screen and(max-width: $sm) {
    font-size: 25px;
  }
}
.text-title-circle-2 {
  font-size: 55px;
  @media screen and(max-width: $xxl) {
    font-size: 45px;
  }
  @media screen and(max-width: $xl) {
    font-size: 36px;
  }
  @media screen and(max-width: $md) {
    font-size: 45px;
  }

  @media screen and(max-width: $sm) {
    font-size: 35px;
  }
}
.subtitle-81658 {
  font-size: 70px;
  @media screen and(max-width: $xxl) {
    font-size: 52px;
  }
  @media screen and(max-width: $xl) {
    font-size: 36px;
  }
  @media screen and(max-width: $md) {
    font-size: 52px;
  }
  @media screen and(max-width: $sm) {
    font-size: 38px;
  }
}
</style>
